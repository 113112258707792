import React, { useState, useEffect } from "react";
import { instance, getConfig } from "../Api/init";
import { useSelector, useDispatch } from "react-redux";
import arrowOption from "../Assets/arrow-option.png";
import noImage from "../Assets/no-photo.png";

const SideBar = (props) => {
  const [selected, setSelected] = useState("");
  const language = useSelector((state) => state.translation.language);
  const [showMoreDish, setShowMoreDish] = useState(false);
  const restaurantName = localStorage.getItem("restaurantName");

  const dispatch = useDispatch();
  const handleToggleDishes = (dish) => {
    setSelected(dish);
    setShowMoreDish(dish);
  };

  useEffect(() => {}, [showMoreDish]);
  const dishesMenuData = useSelector((state) => state.dishesMenuReducer);
  // const selectedLanguage = useSelector((state) => state.translation);

  const getDishesData = async (id) => {
    const response = await instance.get(
      `category-products?categoryId=${id}&restaurantId=1`,
      getConfig()
    );
    // console.log(response.data.category.products);
    dispatch({
      type: "GET_DISHES",
      payload: response.data.sort((a, b) => a.sort_order - b.sort_order),
    });
    // dispatch({
    //   type: "GET_CATEGORIES",
    //   payload: response.data.category.children,
    // });

    if (response) {
      setTimeout(() => {
        props.setIsLoad(false);
      }, 300);
    }
    // setDishes(response.data.products);
  };

  const getMenuData = async () => {
    const restaurantName = localStorage.getItem("restaurantName");
    const response = await instance.get(`categories`, getConfig());
    // console.log(response.data.categories, response);
    // console.log(Object.values(response.data.categories));
    // dispatch({ type: "GET_MENU", payload: response.data.categories });
    dispatch({
      type: "GET_MENU",
      payload: Object.values(response.data.categories),
    });

    // dispatch({
    //   type: "GET_DISCOUNTS",
    //   payload: response.data.discounts,
    // });

    // dispatch({
    //   type: "GET_DISHES_OF_THE_DAY",
    //   payload: response.data.dishesOfTheDay,
    // });

    // setDishes(response.data.categories);

    const responses = await instance.get(
      `category-products?categoryId=${
        Object.values(response.data.categories)[1].id
      }&restaurantId=1`,
      getConfig()
    );
    if (response) {
      props.setIsLoad(false);
    }

    dispatch({
      type: "GET_DISHES",
      payload: responses.data
        .sort((a, b) => a.sort_order - b.sort_order)
        ?.map((d) => ({
          ...d,
          foundations: [
            {
              name: "Wasabi",
              name_en: "Wasabi",
              name_ru: "Васаби",
              name_lt: "Wasabi",
            },
            {
              name: "Soy Sauce",
              name_en: "Soy Sauce",
              name_ru: "Соевый соус",
              name_lt: "Soy Sauce",
            },
          ],
          alergens: [
            {
              name: "Peanuts",
              name_en: "Peanuts",
              name_ru: "Арахис",
              name_lt: "Peanuts",
            },
            {
              name: "Soya",
              name_en: "Soya",
              name_ru: "Соя",
              name_lt: "Soya",
            },
            {
              name: "Eggs",
              name_en: "Eggs",
              name_ru: "Яйца",
              name_lt: "Eggs",
            },
          ],
        })),
    });

    const firstPage = Object.values(response.data.categories)[1];
    dispatch({
      type: "GET_MENU_NAME",
      payload: {
        name: firstPage.name,
        name_en: firstPage.name_en,
        name_ru: firstPage.name_ru,
        name_lt: firstPage.name_lt,
      },
    });

    const additionalsId = response.data.categories?.[0].id;
    instance.get(`categories/${additionalsId}`).then((resp) => {
      dispatch({
        type: "GET_ADDITIONALS",
        payload: resp.data.category.products,
      });
    });
  };

  useEffect(() => {
    getMenuData();
    props.setIsLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoadProducts = (data, id, name, name_en, name_ru, name_lt) => {
    console.log(id, name, name_en, name_ru, name_lt);
    props.setIsLoad(true);
    setSelected(id);
    dispatch({ type: "GET_MENU_ID", payload: id });
    dispatch({
      type: "GET_MENU_NAME",
      payload: { name, name_en, name_ru, name_lt },
    });
    window.scrollTo(0, 0);
    getDishesData(id);
  };

  const onDiscountLoad = (data, name, name_en, name_ru, name_lt) => {
    setSelected("discounts");
    const dishesWithNewPrice = [];

    data.products.forEach((element) => {
      dishesWithNewPrice.push({
        ...element,
        old_price: element.price,
        price: element.price_new,
      });
    });

    dispatch({
      type: "GET_MENU_NAME",
      payload: { name, name_en, name_ru, name_lt },
    });
    dispatch({ type: "GET_MENU_ID", payload: "discounts" });
    dispatch({
      type: "GET_DISCOUNTS_DISHES",
      payload: dishesWithNewPrice,
    });
    dispatch({ type: "GET_CATEGORIES", payload: false });
  };

  const onDishesOfTheDayLoad = (data, name, name_en, name_ru, name_lt) => {
    setSelected("dishesOdTheDay");

    dispatch({
      type: "GET_MENU_NAME",
      payload: { name, name_en, name_ru, name_lt },
    });
    dispatch({ type: "GET_MENU_ID", payload: "dishesOdTheDay" });
    dispatch({
      type: "GET_DISCOUNTS_DISHES",
      payload: data.products,
    });
    dispatch({ type: "GET_CATEGORIES", payload: false });
  };

  console.log(dishesMenuData);
  return (
    <div class="container-fluid" style={{ backgroundColor: "#06181C" }}>
      <div
        style={{
          top: 90,
          bottom: 30,
          position: "fixed",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {/* <div
          class="bar-item"
          style={{
            margin: 10,
            padding: 10,
            cursor: "pointer",
            backgroundColor: "discounts" === selected ? "#0A2930" : "#06181C",
            minWidth: "245px",
            minHeight: "50px",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() =>
            onDiscountLoad(
              dishesMenuData.discounts,
              dishesMenuData.discounts.name,
              dishesMenuData.discounts.name_en,
              dishesMenuData.discounts.name_ru,
              dishesMenuData.discounts.name_lt
            )
          }
        >
          <div
            style={{
              paddingHorizontal: 10,
              alignSelf: "flex-start",
            }}
          >
            <img
              src={
                dishesMenuData.discounts.image
                  ? dishesMenuData.discounts.image
                  : noImage
              }
              alt={dishesMenuData.discounts.name}
              style={{ width: 36, height: 38 }}
            />{" "}
          </div>
          <div
            style={{
              fontWeight: "500",
              fontSize: 13,
              marginLeft: "13px",
              width: "146px",
              textAlign: "left",
              fontStyle: "normal",
            }}
          >
            {language === "ENG" && (
              <span>{dishesMenuData.discounts.name_en}</span>
            )}
            {language === "Pyc" && (
              <span onClick={() => handleToggleDishes(null)}>
                {dishesMenuData.discounts.name_ru
                  ? dishesMenuData.discounts.name_ru
                  : dishesMenuData.discounts.name}
              </span>
            )}
            {language === "EST" && <span>{dishesMenuData.discounts.name}</span>}
            {language === "LV" && (
              <span>
                {dishesMenuData.discounts.name_lt
                  ? dishesMenuData.discounts.dish.name_lt
                  : dishesMenuData.discounts.dish.name}
              </span>
            )}
          </div>
        </div>
        {true && (
          <div
            class="bar-item"
            style={{
              margin: 10,
              padding: 10,
              cursor: "pointer",
              backgroundColor:
                "dishesOdTheDay" === selected ? "#0A2930" : "#06181C",
              minWidth: "245px",
              minHeight: "50px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() =>
              onDishesOfTheDayLoad(
                dishesMenuData.dishesOdTheDay,
                dishesMenuData.dishesOdTheDay.name,
                dishesMenuData.dishesOdTheDay.name_en,
                dishesMenuData.dishesOdTheDay.name_ru,
                dishesMenuData.dishesOdTheDay.name_lt
              )
            }
          >
            <div
              style={{
                paddingHorizontal: 10,
                alignSelf: "flex-start",
              }}
            >
              <img
                src={
                  dishesMenuData.dishesOdTheDay.image
                    ? dishesMenuData.dishesOdTheDay.image
                    : noImage
                }
                alt={dishesMenuData.dishesOdTheDay.name}
                style={{ width: 36, height: 38 }}
              />{" "}
            </div>
            <div
              style={{
                fontWeight: "500",
                fontSize: 13,
                marginLeft: "13px",
                width: "146px",
                textAlign: "left",
                fontStyle: "normal",
              }}
            >
              {language === "ENG" && (
                <span>{dishesMenuData.dishesOdTheDay.name_en}</span>
              )}
              {language === "Pyc" && (
                <span onClick={() => handleToggleDishes(null)}>
                  {dishesMenuData.dishesOdTheDay.name_ru
                    ? dishesMenuData.dishesOdTheDay.name_ru
                    : dishesMenuData.dishesOdTheDay.name}
                </span>
              )}
              {language === "EST" && (
                <span>{dishesMenuData.dishesOdTheDay.name}</span>
              )}
              {language === "LV" && (
                <span>
                  {dishesMenuData.dishesOdTheDay.name_lt
                    ? dishesMenuData.dishesOdTheDay.dish.name_lt
                    : dishesMenuData.dishesOdTheDay.dish.name}
                </span>
              )}
            </div>
          </div>
        )} */}
        {dishesMenuData.menu.map((dish) => {
          return (
            //"#06181C"
            <div
              class="bar-item"
              style={{
                margin: 10,
                padding: 10,
                cursor: "pointer",
                backgroundColor: dish.id === selected ? "#0A2930" : "#06181C",
                minWidth: "245px",
                minHeight: "50px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
              }}
              key={dish.id}
              onClick={() =>
                onLoadProducts(
                  dish,
                  dish.id,
                  dish.name,
                  dish.name_en,
                  dish.name_ru,
                  dish.name_lt
                )
              }
            >
              <div
                style={{
                  paddingHorizontal: 10,
                  alignSelf: "flex-start",
                }}
              >
                <img
                  src={dish.image ?? noImage}
                  alt={dish.name}
                  style={{ width: 36, height: 38 }}
                />{" "}
              </div>
              <div
                style={{
                  fontWeight: "500",
                  fontSize: 13,
                  marginLeft: "13px",
                  width: "146px",
                  textAlign: "left",
                  fontStyle: "normal",
                }}
              >
                {language === "ENG" && <span>{dish.name_en}</span>}
                {language === "Pyc" && (
                  <span onClick={() => handleToggleDishes(null)}>
                    {dish.name_ru ? dish.name_ru : dish.name}
                  </span>
                )}
                {language === "EST" && <span>{dish.name}</span>}
                {language === "LV" && (
                  <span>{dish.name_lt ? dish.name_lt : dish.name}</span>
                )}
                {/* { 
                dish.children.length > 0  &&
                <img 
                  onClick={() => handleToggleDishes(null)} 
                  src={arrowOption} alt={dish.name} 
                  style={{marginLeft: 10, transform:  showMoreDish === dish.id  ? 'rotate(-180deg)' : 'rotate(0deg)'}}
                />
              } */}
                {/* {
                showMoreDish === dish.id &&
                <div style={{fontSize: '10px', marginTop: '13px'}}> 
                 {console.log(dish)}
                {
                  dish.children.map((dishOption, i) => (
                    <div 
                      key={i}
                      onClick={()=> onLoadProductsCategory(dishOption, dishOption.id, dishOption.name, dishOption.name_en, dishOption.name_ru, dishOption.name_lt)}
                      style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}
                    >
                        { dishOption.image && <img src={dishOption.image} alt={dishOption.name} style={{height: 30, width: 30, marginRight: 10}} />}
                        {language === "ENG" && (
                          <span style={{fontSize: '11px'}}>{dishOption.name_en}</span>
                        )}
                        {language === "Pyc" && (
                          <span style={{fontSize: '11px'}}>{dishOption.name_ru ? dishOption.name_ru : dishOption.name}</span>
                        )}
                        {language === "EST" && (
                          <span style={{fontSize: '11px'}}>{dishOption.name}</span>
                        )}
                         {language === "LV" && (
                          <span>{dish.name_lt ? dish.name_lt : dish.name}</span>
                        )}
                    </div>
                  ))
                }
              </div>
             }  */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SideBar;
