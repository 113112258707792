/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NumberFormat from "react-number-format";

import minusround from "../Assets/minusround.png";
import plusround from "../Assets/plusround.png";

import minus from "../Assets/minus.png";
import plus from "../Assets/plus.png";

import cross from "../Assets/Cross.png";
import yellowplus from "../Assets/yellowplus.png";
import icon from "../Assets/i.png";
import axios from "axios";
import { admitInstance } from "../Api/init";
import { useHistory } from "react-router";
import PaymentCheck from "./PaymentCheck";

const RightMenu = (props) => {
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const [promoCode, setPromoCode] = useState("");
  const [iconClickElement, setIconClickElement] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const history = useHistory();
  const cartItems = useSelector((state) => state.cartReducer);
  const additionalsItems = useSelector(
    (state) => state.dishesMenuReducer.additionals
  );
  const restaurantName = localStorage.getItem("restaurantName");
  const [additionals, setAdditionals] = useState([]);
  const [previousOrders, setPreviousOrders] = useState([]);
  const [paymentCheck, setPaymentCheck] = useState(false);
  const [paymentOption, setPaymentOption] = useState(null);
  const language = useSelector((state) => state.translation.language);
  const [orders, setOrders] = useState(cartItems);
  const addItemToCart = (item) => {
    dispatch({ type: "ADD_CART", payload: item });
  };

  const removeItemToCart = (item) => {
    dispatch({ type: "DECREASE_QUANTITY", payload: item });

    const cartValues = cartItems.Carts.reduce((sum, i) => (sum += i.quantity));
    if (cartValues.quantity != undefined) {
      props.closeRightMenu();
    }
  };

  const removeFromCart = (item) => {
    dispatch({ type: "DELETE_CART", payload: item });

    const cartValues = cartItems.Carts.reduce((sum, i) => (sum += i.quantity));
    if (cartValues.quantity != undefined) {
      props.closeRightMenu();
    }
  };

  const cartValue = cartItems.Carts.reduce(
    (sum, i) => (sum += i.quantity * i.price),
    0
  ).toFixed(2);

  useEffect(() => {
    if (cartValue === 0) {
      props.closeRightMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartValue]);

  const iconClick = async (dish) => {
    setIconClickElement(dish);
    // setIsIconClick(true);
  };

  const table_number = localStorage.getItem("table_number");

  const products = [];
  cartItems.Carts.forEach((order, i) => {
    products.push({
      id: order.id,
      name: order.name,
      quantity: order.quantity,
      number: order.number,
      price: order.price,
      foundation_number: order.foundation ? order.foundation.number : null,
      foundation_name: order.foundation ? order.foundation.name : null,
    });
  });
  const data = [];

  useEffect(() => {
    setAdditionals([]);
    cartItems.Carts.forEach((order, i) => {
      if (order.additionals) {
        JSON.parse(order.additionals).forEach((e) => {
          if (!data.includes(e.value)) {
            data.push(e.value);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    data.forEach((e) => {
      let filteredData = additionalsItems.filter(
        (product) => product.name === e
      );
      setAdditionals((prevState) => [
        ...prevState,
        filteredData[filteredData.length - 1],
      ]);
    });
  }, [data]);

  const handleSend = () => {
    setIsClicked(true);
    setPaymentCheck(true);
    // axios.post('http://moodle.rieltcrm.online/api/checkout/store', {
    //    table_number,
    //    restaurant_id: restaurantName,
    //    products,
    //    total:cartValue
    //  }).then(() => {
    //       setIsClicked(false)
    //       props.closeRightMenu();
    //       setIsClicked(true)
    //       history.push(`thanks`);
    //       dispatch({ type: "CLEAN_CARDS", payload: null});
    //  });
    console.log({
      // isCash,
      // waiterTip,
      // rating,
      // comment,
      // promoCode,
      table_number,
      restaurant_id: "5",
      products,
      total: cartValue,
    });

    // props.showPaymentModal();
  };

  useEffect(() => {
    if (paymentOption) {
      if (paymentOption === "now") {
        setPaymentCheck(false);
        props.setPaymentNow(true);
        props.closeRightMenu();
        props.showPaymentModal();
      } else if (paymentOption === "later") {
        setPaymentCheck(false);
        admitInstance
          .post("checkout/store", {
            table_number,
            restaurant_id: "1",
            products: products?.map((p) => ({ ...p, number: p?.id })),
            total: cartValue,
            payment_type: "in_process",
            comment,
          })
          .then((resp) => {
            localStorage.setItem("order_id", resp.data.order_id);
            setIsClicked(false);
            props.closeRightMenu();
            setIsClicked(true);
            history.push(`thanks`);
            dispatch({ type: "CLEAN_CARDS", payload: null });
          });
      }
    }
  }, [paymentOption]);
  const myRef = React.useRef();

  useEffect(() => {
    if (!paymentCheck) {
      setIsClicked(false);
    }
  }, [paymentCheck]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (myRef.current && !myRef.current.contains(event.target)) {
      setIconClickElement(null);
    }
  };

  return (
    <>
      <PaymentCheck
        paymentCheck={paymentCheck}
        setPaymentCheck={setPaymentCheck}
        setPaymentOption={setPaymentOption}
      />
      <div
        class="container-fluid"
        style={{ color: "white", width: "100%", marginTop: 50, zIndex: 5 }}
      >
        {cartItems.Carts.length >= 1 && (
          <div
            class="d-flex"
            style={{
              justifyContent: "space-between",
              padding: 20,
              backgroundColor: "#ECAB04",
            }}
          >
            <div>
              {language === "ENG" && "Your order"}
              {language === "Pyc" && "Ваш заказ"}
              {language === "EST" && "Teie tellimus"}
              {language === "LV" && "Jūsu pasūtījums"}
            </div>
            <div>{cartValue} €</div>
          </div>
        )}
        {previousOrders.length > 1 && (
          <div
            style={{
              marginTop: 20,
              marginBottom: 20,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {language === "ENG" && "Ordered earlier"}
            {language === "Pyc" && "Заказано ранее"}
            {language === "EST" && "Varem tellitud"}
            {language === "LV" && "Užsakyta anksčiau"}
          </div>
        )}
        {previousOrders.length > 1 &&
          cartItems &&
          cartItems.Carts.map((dish) => {
            return (
              <div
                key={dish.id}
                style={{
                  backgroundColor: "#041418",
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-2">
                      <img
                        src={dish.image}
                        alt={dish.name}
                        style={{ width: 80, height: 80 }}
                      ></img>
                    </div>
                    <div class="col-4">
                      <div>
                        {language === "ENG" && <span>{dish.name_en}</span>}
                        {language === "Pyc" && (
                          <span>{dish.name_ru ? dish.name_ru : dish.name}</span>
                        )}
                        {language === "EST" && <span>{dish.name}</span>}
                        {language === "LV" && (
                          <span>{dish.name_lt ? dish.name_lt : dish.name}</span>
                        )}
                      </div>
                      <div
                        style={{
                          marginTop: 5,
                          color: "#ECAB04",
                          fontWeight: "bold",
                        }}
                      >
                        {dish.price} €
                      </div>
                    </div>
                    <div class="col-2" />
                    <div class="col-4">
                      <div class="row" style={{ marginTop: 30 }}>
                        <div class="col-2" />
                        <div class="col-3">
                          <img
                            src={minus}
                            alt={dish.name}
                            style={{ width: 30, height: 30, cursor: "pointer" }}
                            onClick={() => removeItemToCart(dish.id)}
                          ></img>
                        </div>
                        <div class="col-3 text-center">{dish.quantity}</div>

                        <div class="col-3">
                          <img
                            src={plus}
                            alt={dish.name}
                            style={{ width: 30, height: 30, cursor: "pointer" }}
                            onClick={() => addItemToCart(dish)}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        <div>
          {cartItems.Carts.length >= 1 && (
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {language === "ENG" && "Selected"}
              {language === "Pyc" && "Добавлено"}
              {language === "EST" && "Lisatud"}
              {language === "LV" && "Pievienots"}
            </div>
          )}
          <div style={{ maxHeight: 420, overflow: "auto" }}>
            {cartItems.Carts.length >= 1 &&
              cartItems.Carts.map((dish) => {
                return (
                  <div
                    key={dish.id}
                    style={{
                      backgroundColor: "#041418",
                      marginTop: 8,
                      marginBottom: 8,
                    }}
                  >
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-2">
                          <img
                            src={dish.image}
                            alt={dish.name}
                            style={{ width: 70, height: 70 }}
                          ></img>
                        </div>
                        <div
                          class="col-4"
                          style={{ paddingBottom: 17, paddingTop: 17 }}
                        >
                          <div>
                            {language === "ENG" && <span>{dish.name_en}</span>}
                            {language === "Pyc" && (
                              <span>
                                {dish.name_ru ? dish.name_ru : dish.name}
                              </span>
                            )}
                            {language === "EST" && (
                              <span>
                                {dish.name_lt ? dish.name_lt : dish.name}
                              </span>
                            )}

                            {language === "ENG" && dish.foundation && (
                              <div>
                                {dish.foundation.name_en
                                  ? ` (${dish.foundation.name_en})`
                                  : `${dish.foundation.name}`}
                              </div>
                            )}
                            {language === "Pyc" && dish.foundation && (
                              <div>
                                {dish.foundation.name_ru
                                  ? ` (${dish.foundation.name_ru})`
                                  : `${dish.foundation.name}`}
                              </div>
                            )}
                            {language === "EST" && dish.foundation && (
                              <div>({dish.foundation.name})</div>
                            )}
                            {language === "LV" && dish.foundation && (
                              <div>
                                {dish.foundation.name_it
                                  ? ` (${dish.foundation.name_lt})`
                                  : `${dish.foundation.name}`}
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              marginTop: 5,
                              color: "#ECAB04",
                              fontWeight: "bold",
                            }}
                          >
                            {dish.price} €
                          </div>
                        </div>
                        <div class="col-2" />
                        <div class="col-4">
                          <div class="row" style={{ marginTop: 30 }}>
                            <div class="col-2" />
                            <div class="col-3">
                              <img
                                src={minusround}
                                alt={dish.name}
                                style={{
                                  width: 30,
                                  height: 30,
                                  cursor: "pointer",
                                }}
                                onClick={() => removeItemToCart(dish.id)}
                              ></img>
                            </div>
                            <div
                              class="col-3 text-center"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {dish.quantity}
                            </div>

                            <div class="col-3">
                              <img
                                src={plusround}
                                alt={dish.name}
                                style={{
                                  width: 30,
                                  height: 30,
                                  cursor: "pointer",
                                }}
                                onClick={() => addItemToCart(dish)}
                              ></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {/* {cartItems.Carts.length >= 1 && (
          <div
            class="container-fluid"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            {cartItems.Carts && (
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                {language === "ENG" && "Additionally"}
                {language === "Pyc" && "Дополнительно"}
                {language === "EST" && "Lisaks"}
                {language === "LV" && "Papildomai"}
              </div>
            )}
            <div class="row" ref={myRef}>
              {additionals.map((dish) => {
                if (cartItems.Carts.filter(e => e.id === dish.id).length == 0) {
                  return (
                    <div class="col-4" style={{marginBottom: 20}} >
                      <div 
                      
                        class="col-12"
                        key={dish.id}
                        style={{
                          padding: 10,
                          border: "1px solid rgba(255, 255, 255, 0.1)",
                          width: "100%",
                          position: "relative",
                          borderRadius: 5
                        }}
                      >
                        <div class="row" style={{ marginTop: 1, marginBottom: 1, position: "absolute", right: '36px'}}>
                          <div class="col-8"></div>
                          <div class="col-2">
                            {iconClickElement && dish.id === iconClickElement.id && (
                              <div style={{ position: "relative" }}>
                                <div
                                  style={{
                                    position: "absolute",
                                    backgroundColor: "#0A2930",
                                    borderWidth: "1px solid white",
                                    minHeight: 175,
                                    width: 200,
                                    top: 0,
                                    // left: -150,
                                    borderRadius: 10,
                                  }}
                                >
                                  <div class="container-fluid">
                                    <div class="row" style={{ marginTop: 10 }}>
                                      <div
                                        class="col-6"
                                        style={{
                                          color: "rgba(255, 255, 255, 0.5)",
                                        }}
                                      >
                                        {language === "ENG" && "Protein"}
                                        {language === "Pyc" && "Белки"}
                                        {language === "EST" && "Valk"}
                                        {language === "LV" && "Baltymas"}
                                      </div>
                                      <div class="col-6">12 r </div>
                                    </div>
                                    <div class="row" style={{ marginTop: 10 }}>
                                      <div
                                        class="col-6"
                                        style={{
                                          color: "rgba(255, 255, 255, 0.5)",
                                        }}
                                      >
                                        {language === "ENG" && "Fats"}
                                        {language === "Pyc" && "Жиры"}
                                        {language === "EST" && "Rasvad"}
                                        {language === "LV" && "Riebalai"}
                                      </div>
                                      <div class="col-6">12 r </div>
                                    </div>
                                    <div class="row" style={{ marginTop: 10 }}>
                                      <div
                                        class="col-6"
                                        style={{
                                          color: "rgba(255, 255, 255, 0.5)",
                                        }}
                                      >
                                        {language === "ENG" && "Carbohydrates"}
                                        {language === "Pyc" && "Углеводы"}
                                        {language === "EST" && "Süsivesikud"}
                                        {language === "LV" && "Angliavandeniai"}
                                      </div>
                                      <div class="col-6">12 r </div>
                                    </div>
                                    <div class="row" style={{ marginTop: 10 }}>
                                      <div
                                        class="col-6"
                                        style={{
                                          color: "rgba(255, 255, 255, 0.5)",
                                        }}
                                      >
                                        {language === "ENG" && "Weight"}
                                        {language === "Pyc" && "Вес"}
                                        {language === "EST" && "Kaal"}
                                        {language === "LV" && "Svoris"}
                                      </div>
                                      <div class="col-6">250 </div>
                                    </div>
                                  </div>
                                  <div
                                    class="row"
                                    style={{
                                      marginTop: 5,
                                    }}
                                  >
                                    <div class="col-1"></div>
  
                                    <div class="col-7">
                                        {language === "ENG" && "Allergens:"}
                                        {language === "Pyc" && "Аллергены:"}
                                        {language === "EST" && "Allergeenid:"}   
                                        {language === "EST" && "Alergenai:"}   
                                    </div>
                                  </div>
  
                                  <div
                                    class="row"
                                    style={{
                                      marginTop: 5,
                                      color: "rgba(255, 255, 255, 0.5)",
                                    }}
                                  >
                                    <div style={{ marginLeft: 20 }}>
                                      {language === "ENG" && "Shrimp, Lactose, Gluten, Curry"}
                                      {language === "Pyc" && "Креветки, Лактоза, Глютен, Карри"}
                                      {language === "EST" && "Krevetid, laktoos, gluteen, karri"}   
                                      {language === "EST" && "Krevetės, laktozė, glitimas, karis"}   
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="row" style={{ justifyContent: "center" }}>
                          <img
                            src={dish.image}
                            alt={dish.name}
                            style={{ width: 120, height: 120 }}
                          ></img>
                          <div
                            class="row"
                            style={{ marginTop: 10, marginBottom: 25, minHeight: 42 }}
                          >
                            <div>
                                {language === "ENG" && (
                                  <span>{dish.name_en}</span>
                                )}
                                {language === "Pyc" && (
                                  <span>{dish.name_ru ? dish.name_ru : dish.name}</span>
                                )}
                                 {language === "EST" && (
                                  <span>{dish.name}</span>
                                )}
                                {language === "LV" && (
                                  <span>{dish.name_lt ? dish.name_lt : dish.name}</span>
                                )}
                            </div>
                          </div>
                          <div class="row" style={{marginBottom: 18}}>
                            <span>
                                <span className="btn-cart"
                                  style={{
                                      border: "1px solid rgba(236, 171, 4, 0.3)",
                                      borderRadius: '150px',
                                      padding: '9px 11px',
                                      minWidth :'85px',
                                      height: '40px',
                                      fontWeight: 'bold',
                                      fontSize: '16px',
                                      lineHeight: '19px',
                                      color: "#ECAB04",

                                  }} onClick={() => {addItemToCart(dish);}}> 
                                  <span style={{ color: "#ECAB04",fontWeight: '500'}}>+</span> {dish.price} €
                                </span>
                              </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    // </div>
                  );
                }
              })}
            </div>
          </div>
        )} */}
        {cartItems.Carts.length >= 1 && (
          <div
            class="container-fluid"
            style={{ margin: 5, marginTop: 15, marginBottom: 15 }}
          >
            <div class="row">
              {" "}
              <input
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder={
                  language === "ENG"
                    ? "Comment"
                    : language === "Pyc"
                    ? "Комментарий"
                    : language === "EST"
                    ? "Kommentaar"
                    : language === "LV"
                    ? "Komentēt"
                    : ""
                }
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  outline: "none",
                  borderBottom: "1px  solid rgba(255, 255, 255, 0.4)",
                  color: "white",
                  width: "80%",
                  margin: 10,
                  marginTop: 20,
                  marginBottom: 20,
                  padding: 10,
                }}
              />
            </div>
            {/* <div class="row" style={{ margin: 10, marginBottom: 25 }}>
              <div
                class="col-4"
                style={{ color: "rgba(255, 255, 255, 0.4)", marginTop: 5 }}
              >
                {language === "ENG" && "Promo code"}
                {language === "Pyc" && "Промо код"}
                {language === "EST" && "Promo kood"}
                {language === "LV" && "Reklamos kodas"}
              </div>
              <div class="col-8">
                <NumberFormat
                  class="text-center"
                  type="text"
                  format="###-###-###"
                  // mask="-"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                  placeholder="xxx-xxx-xxx"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    outline: "none",
                    border: "none",
                    color: "white",
                    width: "60%",
                    padding: 8,
                  }}
                />
              </div>
            </div> */}
          </div>
        )}
        {cartItems.Carts.length >= 1 && (
          <div class="container-fluid" style={{ marginBottom: 80 }}>
            <div class="row">
              <div
                onClick={!isClicked ? handleSend : null}
                class="d-flex"
                style={{
                  justifyContent: "space-between",
                  padding: 20,
                  backgroundColor: "#ECAB04",
                  cursor: "pointer",
                }}
              >
                <div>
                  {language === "ENG" && "Order"}
                  {language === "Pyc" && "Заказать"}
                  {language === "EST" && "Tellida"}
                  {language === "LV" && "Pasūtīt"}
                </div>
                <div>{cartValue} €</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RightMenu;
