import axios from "axios";

// export const instance = axios.create({baseURL: "https://mysushi.ee/public/api/"});
export const admitInstance = axios.create({
  baseURL: "https://test14.dkl-logistics.org.ua/api/",
});
export const instance = axios.create({
  baseURL: "https://test14.dkl-logistics.org.ua/api/",
});

export const getConfig = () => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  return { headers };
};
