export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const GET_CURRENT_RESTAURANT = "GET_CURRENT_RESTAURANT";

const initialState = {
  language: "Pyc",
  currentRestaurant: null,
};

const translationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return { ...state, language: action.payload ?? "Pyc" };
    case GET_CURRENT_RESTAURANT:
      return { ...state, currentRestaurant: action.payload };

    default:
      return state;
  }
};

export default translationReducer;

// {selectedLanguage.language === "ENG" && <span></span>  }
// {selectedLanguage.language === "Pyc" && <span></span>  }
// {selectedLanguage.language === "EST" && <span></span> }
